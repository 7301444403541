import { TextPicTeaserItem } from '@contentfulTypes'
import { StageMedia } from './StageMedia'
import { TextPicItemContent } from './TextPicItemContent'
import { Link } from '~/elements/Link/Link'
import themeConfig from 'theme'
import { CtaData, useGetCtaHref } from '~/hooks/useGetCtaHref'

type TextPicItemProps = {
  data: TextPicTeaserItem
  isFirstItem: boolean
  isActive: boolean
}
export const TextPicItem = ({ data, isFirstItem, isActive }: TextPicItemProps) => {
  const mediaSettings = {
    desktop: {
      media: 'lgx' as keyof typeof themeConfig.theme.screens,
      video: data.video,
      image: {
        data: data.desktopImage,
        fill: false,
        sizes: '',
        className: 'w-full h-full object-cover max-h-screen',
        priority: isFirstItem,
      },
    },
    mobile: {
      media: 'lgx' as keyof typeof themeConfig.theme.screens,
      video: data?.videoMobile,
      image: {
        data: data.mobileImage,
        fill: false,
        sizes: '',
        className: 'h-full object-cover aspect-[1/1.1]',
        priority: isFirstItem,
      },
    },
  }
  const href = useGetCtaHref(data.cta as CtaData)

  const contentData = {
    textColor: data.textColor,
    headline: data.headline,
    subline: data.subline,
    cta: data.cta as CtaData,
  }

  return (
    <div
      className="relative flex h-full w-full flex-col-reverse gap-[50px] px-5 lg:grid lg:grid-cols-[50%_50%] lg:gap-0 lg:px-0"
      style={{
        background: data.backgroundColor as string,
      }}
    >
      <Link href={href ?? ''} className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer" />
      <div className="pb-[90px] lg:pb-0">
        <div className="w-full max-w-[1414px] lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:px-5 lgx:px-[30px]">
          <TextPicItemContent data={contentData} isActive={isActive} />
        </div>
      </div>
      <div className="relative h-full w-full pt-[128px] lg:pt-0">
        <StageMedia settings={mediaSettings} />
      </div>
    </div>
  )
}
