import clsx from 'clsx'
import { PropsWithChildren } from 'react'

interface AnimatedTextProps {
  isVisible: boolean
  delay?: number
  className?: string
  textClassName?: string
  duration?: number
}

export const AnimatedText: React.FC<PropsWithChildren<AnimatedTextProps>> = ({
  children,
  isVisible,
  delay = 0,
  className = '',
  textClassName = '',
  duration = 500,
}) => {
  return (
    <span className={clsx(className, 'block overflow-hidden')}>
      <span
        className={clsx(
          'block transition-transform ease-out',
          { 'translate-y-full': !isVisible },
          textClassName,
        )}
        style={{
          transitionDuration: `${duration}ms`,
          transitionDelay: `${delay}ms`,
        }}
      >
        {children}
      </span>
    </span>
  )
}
