import clsx from 'clsx'
import themeConfig from 'theme'
import { HeroStageItem } from '@contentfulTypes'
import { HeroStageCountdown } from '~/modules/HeroStageModule/components/HeroStageCountdown'
import { ButtonCta } from '~/elements/Button/ButtonCta'
import { useGetCtaHref, CtaData } from '~/hooks/useGetCtaHref'
import { useEffect, useState } from 'react'
import { Link } from '~/elements/Link/Link'
import { MultilineAnimatedText } from '~/modules/AnimatedText/MultilineAnimatedText'
import { AnimatedText } from '~/elements/AnimatedText/AnimatedText'
import { StageMedia } from '~/modules/TextPicTeaserModule/components/StageMedia'

interface HeroStageModuleItemProps {
  item: HeroStageItem
  isFirstItem: boolean
  isActive?: boolean
}

const isCountdownDateInFuture = (date: string | Date): boolean => {
  const targetTime = new Date(date).getTime()
  const currentTime = new Date().getTime()

  return targetTime > currentTime
}

const setColor = (theme?: string | null) => {
  if (theme) {
    return theme.toLowerCase() === 'dark' ? 'text-sg-black' : 'text-sg-white'
  }

  return 'text-sg-black'
}

export const HeroStageModuleItem = ({ item, isFirstItem, isActive }: HeroStageModuleItemProps) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setShowContent(true)
      }, 10)

      return () => clearTimeout(timer)
    } else {
      setShowContent(false)
    }
  }, [isActive])

  const sublineClasses = clsx(
    'font-extralight',
    showContent ? 'text-20-25-sg' : 'text-[20px] leading-loose',
    setColor(item.theme),
  )

  const headlineClasses = clsx(
    'text-40-45-sg lgx:text-70-80-sg font-extralight',
    setColor(item.theme),
  )

  const copyTextClasses = clsx('text-15-20-sg font-extralight', setColor(item.theme))

  const TEXT_ANIMATION_DURATION = 300

  const ctaHref = useGetCtaHref(item.cta as CtaData)

  const mediaSettings = {
    desktop: {
      media: 'lgx' as keyof typeof themeConfig.theme.screens,
      video: item.video,
      image: {
        data: item.desktopImage,
        fill: true,
        sizes: '(min-width: 1024px) 100vw, 0vw',
        className: 'absolute inset-0 h-full w-full object-cover',
        priority: isFirstItem,
      },
    },
    mobile: {
      media: 'lgx' as keyof typeof themeConfig.theme.screens,
      video: item.videoMobile,
      image: {
        data: item.mobileImage,
        fill: true,
        sizes: '(max-width: 1023px) 100vw, 0vw',
        className: 'absolute inset-0 h-full w-full object-cover',
        priority: isFirstItem,
      },
    },
  }

  return (
    <div className="relative flex h-screen w-full items-center justify-center pt-[calc(var(--header-padding)/2)] lgx:px-5">
      <StageMedia settings={mediaSettings} />

      {ctaHref && (
        <Link href={ctaHref ?? ''} className="absolute top-0 right-0 bottom-0 left-0 lgx:hidden" />
      )}
      <div className="flex w-full justify-center">
        <div className="relative flex w-full max-w-[1414px] justify-between px-5 lgx:px-[30px]">
          {ctaHref && (
            <Link
              href={ctaHref ?? ''}
              className="absolute hidden lgx:-inset-x-4 lgx:-inset-y-8 lgx:block"
            />
          )}
          <div className="pointer-events-none relative z-10 max-w-[650px]">
            {item.subline && (
              <p className="mb-2.5 max-w-[410px] lgx:mb-0">
                <AnimatedText
                  isVisible={showContent}
                  textClassName={sublineClasses}
                  duration={TEXT_ANIMATION_DURATION}
                >
                  {item.subline}
                </AnimatedText>
              </p>
            )}
            {item.headline && (
              <p>
                <MultilineAnimatedText
                  text={item.headline as string}
                  showContent={showContent}
                  renderText={(word) => (
                    <span className={headlineClasses} dangerouslySetInnerHTML={{ __html: word }} />
                  )}
                />
              </p>
            )}
            {item.copyText && (
              <p className="mt-2.5 max-w-[410px]">
                <MultilineAnimatedText
                  text={item.copyText as string}
                  showContent={showContent}
                  renderText={(word) => (
                    <span className={copyTextClasses} dangerouslySetInnerHTML={{ __html: word }} />
                  )}
                />
              </p>
            )}
            {item.countdownDate && isCountdownDateInFuture(item.countdownDate) && (
              <div className="overflow-hidden">
                <HeroStageCountdown
                  className={clsx(
                    'mt-2.5 transition-transform duration-1000 ease-out lgx:mt-[30px]',
                    showContent ? 'translate-y-0' : 'translate-y-full',
                  )}
                  targetDate={item.countdownDate}
                />
              </div>
            )}
            {item.cta && (
              <ButtonCta
                cta={item.cta}
                className={clsx(
                  'pointer-events-auto mt-5 transition-opacity duration-1000 ease-in-out lgx:mt-[30px]',
                  showContent ? 'opacity-100' : 'opacity-0',
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
