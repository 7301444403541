export const TextPicModulePlaceholder = () => {
  return (
    <div className="content-width flex h-screen w-full grid-cols-2 flex-col-reverse bg-sg-light-grey lg:grid">
      <div className="relative h-fit items-center p-10 lg:grid lg:h-full lg:p-20">
        <div className="grid gap-5">
          <div className="animate-pulse">
            <div className="h-4 w-3/4 rounded bg-sg-dark-grey"></div>
          </div>
          <div className="mt-2.5 mb-5 animate-pulse lg:mb-0">
            <div className="h-6 w-1/2 rounded bg-sg-dark-grey"></div>
          </div>
          <div className="animate-pulse">
            <div className="h-6 w-1/3 rounded bg-sg-dark-grey leading-[15px]"></div>
          </div>
        </div>
      </div>
      <div className="h-full w-full animate-pulse bg-sg-dark-grey" />
    </div>
  )
}
