import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { ButtonCta } from '~/elements/Button/ButtonCta'
import { CtaData } from '~/hooks/useGetCtaHref'
import { MultilineAnimatedText } from '~/modules/AnimatedText/MultilineAnimatedText'

type TextPicItemContentProps = {
  data: {
    textColor?: string | null
    headline?: string | null
    subline?: string | null
    cta: CtaData
  }
  isActive: boolean
}

export const TextPicItemContent = ({ data, isActive }: TextPicItemContentProps) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setShowContent(true)
      }, 50)

      return () => clearTimeout(timer)
    } else {
      setShowContent(false)
    }
  }, [isActive])

  return (
    <div
      className="max-w-[530px] lgx:pl-5 xl:pl-0"
      style={{
        color: data.textColor as string,
      }}
    >
      <p
        className={clsx(
          'text-15-20-sg font-extralight transition-opacity duration-500 lg:text-20-25-sg lg:leading-[25px]',
          showContent ? 'opacity-1' : 'opacity-0',
        )}
      >
        {data.subline}
      </p>
      <div className="mt-2.5 mb-5 lg:mb-0">
        <h3>
          <MultilineAnimatedText
            text={data.headline as string}
            showContent={showContent}
            renderText={(word) => (
              <span
                className="text-30-35-sg font-extralight lg:text-70-80-sg"
                dangerouslySetInnerHTML={{ __html: word }}
              />
            )}
          />
        </h3>
      </div>
      <ButtonCta
        cta={data.cta as CtaData}
        className={clsx('transition-opacity duration-300', showContent ? 'opacity-1' : 'opacity-0')}
      />
    </div>
  )
}
