import clsx from 'clsx'
import { SeoTextRenderer, SeoTextLinks } from '../SeoTextModule/SeoTextRenderer'

interface SeoTextProps {
  className?: string
  headline: string
  seoText: any
  links?: SeoTextLinks
  isEmbedded?: boolean
}

export const SeoText: React.FC<SeoTextProps> = ({
  headline,
  seoText,
  className,
  links,
  isEmbedded,
}) => {
  return (
    <div className={clsx('text-sg-black/75', isEmbedded && 'my-5', className)}>
      <h2 className="mb-[20px] text-20-25-sg font-medium text-sg-black">{headline}</h2>
      <SeoTextRenderer content={seoText} links={links} />
    </div>
  )
}
