import { ProductsSlider } from '~/modules/ProductsSlider/ProductsSlider'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useProductsSliderQuery } from '@contentfulTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import {
  ConfigurableProduct,
  useRecentlyViewedProductsQuery,
  useRecentlyViewedProductsViaSkuQuery,
} from '@magentoTypes'
import { LoadingItems } from './components/LoadingItems'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import clsx from 'clsx'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { ProductCard } from '../ProductCard/ProductCard'
import { useMemo } from 'react'
import { mapProduct } from '../SearchResultPageCio/lib/mapProduct'
import { useCioRecommendations } from '../CioRecommendations/hooks/useCioRecommendations'
import { getSpacingClasses } from '~/modules/helpers/getSpacingClasses'

type Props = {
  id: string
  className?: string
}

const RecommendedProducts = ({ id, className }: Props) => {
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const isCioEnabled = !!process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS

  const { data: contentfulData, isLoading: isLoadingContentful } = useProductsSliderQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: finalLocale,
    },
    { enabled: !!id },
  )
  const link = contentfulData?.productsSlider?.link

  const topSpacingClass = getSpacingClasses(contentfulData?.productsSlider?.spacingTop, 'top')
  const bottomSpacingClass = getSpacingClasses(
    contentfulData?.productsSlider?.spacingBottom,
    'bottom',
  )

  const { data: recentlyViewedData, isLoading: isLoadingMagento } =
    useRecentlyViewedProductsViaSkuQuery(
      magentoClient,
      {
        skus: contentfulData?.productsSlider?.skus as string[],
      },
      {
        enabled:
          (!isCioEnabled ||
            (isCioEnabled && !contentfulData?.productsSlider?.cioRecommendationsId)) &&
          !!contentfulData?.productsSlider?.skus &&
          contentfulData?.productsSlider?.skus?.length > 0,
      },
    )
  const { data: recentlyViewedDataUrlKey, isLoading: isLoadingMagentoUrlKey } =
    useRecentlyViewedProductsQuery(
      magentoClient,
      {
        urlKeys: contentfulData?.productsSlider?.urlKey as string[],
      },
      {
        enabled:
          (!isCioEnabled ||
            (isCioEnabled && !contentfulData?.productsSlider?.cioRecommendationsId)) &&
          !!contentfulData?.productsSlider?.urlKey &&
          contentfulData?.productsSlider?.urlKey?.length > 0,
      },
    )

  const cioFilters =
    contentfulData?.productsSlider?.cioRecommendationsFilterName &&
    contentfulData?.productsSlider?.cioRecommendationsFilterValue
      ? {
          [contentfulData.productsSlider.cioRecommendationsFilterName]:
            contentfulData.productsSlider.cioRecommendationsFilterValue,
        }
      : undefined

  const {
    recommendations,
    podId,
    resultId,
    totalResultsFound,
    isLoading: isLoadingCio,
  } = useCioRecommendations(contentfulData?.productsSlider?.cioRecommendationsId, {
    filters: cioFilters,
    numResults: contentfulData?.productsSlider?.cioRecommendationsResultsLimit || 10,
  })

  const products: ConfigurableProduct[] = useMemo(() => {
    if (isCioEnabled && contentfulData?.productsSlider?.cioRecommendationsId) {
      return recommendations?.map((item) => mapProduct(item)) as ConfigurableProduct[]
    } else {
      const productsData = recentlyViewedData
        ? (recentlyViewedData?.products?.items as ConfigurableProduct[])
        : (recentlyViewedDataUrlKey?.products?.items as ConfigurableProduct[])
      const sortingArr = contentfulData?.productsSlider?.skus
      if (productsData && sortingArr) {
        return productsData.sort(
          (a, b) => sortingArr.indexOf(a?.sku as string) - sortingArr.indexOf(b?.sku as string),
        )
      }
      return []
    }
  }, [recentlyViewedData, recentlyViewedDataUrlKey, recommendations, contentfulData, isCioEnabled])

  const loadingState =
    isCioEnabled && contentfulData?.productsSlider?.cioRecommendationsId
      ? isLoadingCio
      : !!contentfulData?.productsSlider?.skus
      ? isLoadingMagento
      : isLoadingMagentoUrlKey

  return (
    <section
      className={clsx(
        className,
        'content-width overflow-hidden pl-5 lg:px-[50px]',
        topSpacingClass,
        bottomSpacingClass,
      )}
      data-cnstrc-recommendations={
        isCioEnabled && contentfulData?.productsSlider?.cioRecommendationsId ? 'true' : undefined
      }
      data-cnstrc-recommendations-pod-id={isCioEnabled ? podId : undefined}
      data-cnstrc-result-id={isCioEnabled ? resultId : undefined}
      data-cnstrc-num-results={
        isCioEnabled ? (isLoadingCio ? undefined : totalResultsFound || 0) : undefined
      }
    >
      <div className="max-w-[1354px]">
        {isLoadingContentful || loadingState ? (
          <LoadingItems />
        ) : (
          <ProductsSlider
            products={products as ConfigurableProduct[]}
            headlineText={contentfulData?.productsSlider?.headline ?? ''}
            link={link}
            renderSlide={(product, index, isSlideVisible) => {
              return (
                <ProductCard
                  className={clsx(
                    'border-none !p-0 transition-opacity duration-500',
                    isSlideVisible ? 'opacity-100' : 'opacity-0',
                  )}
                  cioItemType={isCioEnabled ? 'recommendation' : undefined}
                  cioStrategyId={
                    recommendations?.find((_, recoIndex) => index === recoIndex)?.strategy?.id ||
                    undefined
                  }
                  product={product}
                  productPrice={product}
                  isSlider
                  withCioAttributes
                  useCio
                />
              )
            }}
          />
        )}
      </div>
    </section>
  )
}

export default RecommendedProducts
