import { MouseEvent, useRef, useState } from 'react'
import { useStageQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import clsx from 'clsx'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { isExternal } from '~/lib/externalLink'
import { NextSeo } from 'next-seo'
import PrevStage from './PrevStage'
import NewStage from './NewStage'
import useCountdownData from '~/hooks/useCountdownData'

type StageProps = {
  mainStage?: boolean
  id: string
  isBlogWithoutOgImage?: boolean
  imageLoadStrategy: 'lazy' | 'eager'
}

const Stage = ({
  mainStage = false,
  id,
  isBlogWithoutOgImage = false,
  imageLoadStrategy = 'lazy',
}: StageProps) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { dataLayer } = useGTM()
  const externalLinkRef = useRef<HTMLAnchorElement>(null)
  const { data } = useStageQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const margin = useMarginMaker(
    data?.stage?.marginBottom as string,
    data?.stage?.marginBottomMobile as string,
  )

  const [isPromoCodeCopied, setisPromoCodeCopied] = useState(false)

  const onNavItemClick = (e: MouseEvent) => {
    if (data?.stage?.promoCreativeName !== null) {
      dataLayer(DataLayerEnum.EVENT, 'select_promotion', {
        creative_name: data?.stage?.promoCreativeName?.dataLayerMetaContent,
        creative_slot: 'hp_teaser',
      })
    } else {
      dataLayer(DataLayerEnum.EVENT, 'select_content', {
        content_type: data?.stage?.metaContentType?.dataLayerMetaContent,
        content_name: data?.stage?.metaContentName?.dataLayerMetaContent,
      })
    }

    if ((e.target as HTMLElement)?.getAttribute('data-promo-code')) {
      return
    } else {
      if (cta?.linkExistingPage?.slug && !isExternal(cta?.linkExistingPage?.slug)) {
        router.push(cta?.linkExistingPage?.slug)
      }
      if (cta?.linkExternalPage && isExternal(cta?.linkExternalPage)) {
        externalLinkRef.current?.click()
      }
      if (cta?.linkInternalUrl) {
        router.push(cta?.linkInternalUrl)
      }
    }
  }

  const copyToClipboard: () => void = () => {
    navigator?.clipboard?.writeText(data?.stage?.countDown?.discountCode as string)
    setisPromoCodeCopied(true)
  }

  const stageLarge = data?.stage?.type === 'Stage Large'

  const countdownData = useCountdownData({
    isBanderoleVisible:
      data?.stage?.countDown?.countDownDate &&
      !data?.stage?.countDown?.hideStageCountdown &&
      stageLarge,
    banderoleData: data?.stage?.countDown?.countDownDate,
    rerenderCondition: data?.stage?.countDown,
  })

  if (!data?.stage) return null
  const { darkText, cta, hasBackgroundGradient, newStage } = data?.stage

  const stageOgImageUrlBlogPost =
    isBlogWithoutOgImage &&
    data?.stage?.image?.url?.replace('downloads.ctfassets.net', 'images.ctfassets.net')

  return (
    <>
      {isBlogWithoutOgImage && data?.stage?.image && (
        <NextSeo
          openGraph={{
            images: [
              {
                url: stageOgImageUrlBlogPost + '?w=1200&q=70',
                width: 1200,
                height:
                  Math.round(
                    ((data.stage.image?.height || 1) / (data.stage.image?.width || 1)) * 1200,
                  ) || 0,
              },
            ],
          }}
        />
      )}
      {newStage ? (
        <NewStage
          id={id}
          data={data}
          mainStage={mainStage}
          countdownData={countdownData}
          margin={margin}
          onNavItemClick={onNavItemClick}
          isPromoCodeCopied={isPromoCodeCopied}
          copyToClipboard={copyToClipboard}
          stageLarge={stageLarge}
          imageLoadStrategy={imageLoadStrategy}
        >
          {hasBackgroundGradient ? (
            <div
              className={clsx(
                'absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-b from-[rgba(0,0,0,0)] ',
                darkText ? 'to-[rgba(255,255,255,0.4)]' : 'to-[rgba(0,0,0,0.4)]',
              )}
            />
          ) : null}
          {cta?.linkExternalPage && (
            <a
              ref={externalLinkRef}
              href={cta.linkExternalPage}
              target="_self"
              rel="noopener noreferrer"
              className="invisible absolute top-0 left-0 -z-10 h-0 w-0"
            />
          )}
        </NewStage>
      ) : (
        <PrevStage
          id={id}
          data={data}
          mainStage={mainStage}
          countdownData={countdownData}
          margin={margin}
          onNavItemClick={onNavItemClick}
          isPromoCodeCopied={isPromoCodeCopied}
          copyToClipboard={copyToClipboard}
          stageLarge={stageLarge}
        >
          {hasBackgroundGradient ? (
            <div
              className={clsx(
                'absolute top-0 left-0 bottom-0 right-0 bg-gradient-to-b from-[rgba(0,0,0,0)] ',
                darkText ? 'to-[rgba(255,255,255,0.4)]' : 'to-[rgba(0,0,0,0.4)]',
              )}
            />
          ) : null}
          {cta?.linkExternalPage && (
            <a
              ref={externalLinkRef}
              href={cta.linkExternalPage}
              target="_self"
              rel="noopener noreferrer"
              className="invisible absolute top-0 left-0 -z-10 h-0 w-0"
            />
          )}
        </PrevStage>
      )}
    </>
  )
}

export default Stage
