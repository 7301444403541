export const CategorySliderModulePlaceholder = () => {
  return (
    <div className="flex w-full justify-center">
      <div className="w-full max-w-[1354px]">
        <div className=" w-full animate-pulse pb-7">
          <div className="flex h-full w-full items-center justify-between pb-7.5">
            <div className="h-4 w-1/2 rounded bg-sg-light-grey" />
            <div className="hidden gap-2.5 lg:flex">
              <div className="h-[54px] w-[54px] rounded-[50%] bg-sg-light-grey" />
              <div className="h-[54px] w-[54px] rounded-[50%] bg-sg-light-grey" />
            </div>
          </div>
          <div className="grid auto-cols-[60%] grid-flow-col gap-2.5 overflow-x-hidden sm:grid-cols-3 md:grid-cols-4">
            {[...Array(4).keys()].map((el) => (
              <div
                key={el}
                className="flex h-full flex-col [&:nth-child(n+3)]:hidden sm:[&:nth-child(n+4)]:flex md:[&:nth-child(n+2)]:flex"
              >
                <div className="aspect-[1/1.5] h-full w-full self-stretch rounded bg-sg-light-grey lg:aspect-[1/1.4]" />
                <div className="mt-2 h-6 w-3/4 rounded bg-sg-light-grey" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
