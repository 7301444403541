import { useHeroStageModuleQuery, HeroStageItem } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { HeroStageModuleItem } from './components/HeroStageModuleItem'
import { ColorTheme } from '~/lib/constants/themeConstants'
import { StageSliderCarouselModule } from '../StageSliderCarouselModule/StageSliderCarouselModule'
import { StageSliderCarouselProgressBar } from '../StageSliderCarouselModule/components/StageSliderCarouselProgressBar'

interface HeroStageModuleProps {
  id: string
}

export const HeroStageModule = ({ id }: HeroStageModuleProps) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useHeroStageModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const heroStageModule = data?.heroStageModule
  const items = heroStageModule?.heroStageItemsCollection?.items ?? []

  return (
    <div className="h-screen">
      <StageSliderCarouselModule
        displayNavigation={items.length > 1}
        displayProgressBar={items.length > 1}
        items={items}
      >
        {{
          slideComponent: (
            slideData: HeroStageItem & { theme: ColorTheme },
            index: number,
            isActive,
          ) => {
            return (
              <HeroStageModuleItem
                key={index}
                isFirstItem={index === 0}
                isActive={isActive}
                item={slideData as HeroStageItem}
              />
            )
          },
          customProgressBar: (progressPercentage, handleClick, theme) => {
            return (
              <div className="z-20">
                <StageSliderCarouselProgressBar
                  theme={theme?.toLowerCase() as ColorTheme}
                  progressPercentage={progressPercentage}
                  handleClick={handleClick}
                />
              </div>
            )
          },
        }}
      </StageSliderCarouselModule>
    </div>
  )
}
