import clsx from 'clsx'
import { useState } from 'react'
import { useTabModuleQuery, RteRteLinks } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { ButtonMain } from '~/elements/Button/ButtonMain'

type Props = {
  id: string
}

const TabContent = ({
  content,
  links,
  isActive,
}: {
  content: any
  links: RteRteLinks
  isActive: boolean
}) => {
  return (
    <div
      className={clsx(
        'grid w-full transition-opacity duration-500 ease-in-out',
        isActive ? 'opacity-100' : 'h-0 overflow-hidden opacity-0',
      )}
    >
      <RTEParser
        className="font-extralight [&_p]:text-15-22-sg"
        rteJSONContent={content}
        links={links}
      />
    </div>
  )
}

const TabModule = ({ id }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useTabModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.tabModule?.marginBottom as string,
    data?.tabModule?.marginBottomMobile as string,
  )

  const handleTabChange = (index: number) => {
    if (index === activeIndex) return
    setActiveIndex(index)
  }

  return (
    <section className="relative m-4 flex w-auto flex-col gap-8 lg:m-20 lg:flex-row" style={margin}>
      <Jumplink id={id} />
      <menu className="flex min-w-[25%] flex-col gap-2">
        {data?.tabModule?.tabsCollection?.items?.length
          ? data?.tabModule?.tabsCollection?.items.map((item, i) => (
              <li key={item?.sys.id}>
                <ButtonMain
                  className={clsx('px-8')}
                  fullWidth
                  type="button"
                  variant={activeIndex === i ? 'Main' : 'Secondary'}
                  onClick={() => handleTabChange(i)}
                >
                  {item?.headline}
                </ButtonMain>
              </li>
            ))
          : null}
      </menu>
      <div className="relative w-full">
        {data?.tabModule?.tabsCollection?.items?.map((item, i) => (
          <TabContent
            key={item?.sys.id}
            content={item?.content?.rte?.json}
            links={item?.content?.rte?.links as RteRteLinks}
            isActive={activeIndex === i}
          />
        ))}
      </div>
    </section>
  )
}

export default TabModule
