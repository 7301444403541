import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'

interface HeroStageCountdownProps {
  className?: string
  targetDate: string | Date
}

export const HeroStageCountdown = ({ className, targetDate }: HeroStageCountdownProps) => {
  const { t } = useTranslation()

  const [currentTime, setCurrentTime] = useState<number>(Date.now())

  const timeLeft = useMemo(() => {
    const target = typeof targetDate === 'string' ? new Date(targetDate) : targetDate
    const difference = +target - currentTime

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }, [targetDate, currentTime])

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now())
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString()
  }

  const timeUnits = [
    { value: timeLeft.days, label: t('Tage') },
    { value: timeLeft.hours, label: t('Hours') },
    { value: timeLeft.minutes, label: t('Minutes') },
    { value: timeLeft.seconds, label: t('Seconds') },
  ]

  return (
    <div className={`flex space-x-4 font-extralight text-sg-neon-yellow ${className}`}>
      {timeUnits.map((unit, index) => (
        <div key={unit.label} className="flex flex-col">
          <div className="text-50-60-sg">
            {addLeadingZero(unit.value)}
            {index < timeUnits.length - 1 && ' :'}
          </div>
          <div className="text-15-20-sg">{unit.label}</div>
        </div>
      ))}
    </div>
  )
}
