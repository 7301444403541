import { Image } from '~/elements/Image/Image'
import { Media } from '~/lib/Media'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { VideoPlayer } from '~/elements/VideoPlayer/VideoPlayer'
import { Asset } from '@contentfulTypes'
import { TailwindBreakpoints } from '~/modules/Carousel/hooks/useGetSlidesSetup'

type StageImageSettings = {
  data?: Asset | null
  fill: boolean
  sizes: string
  className: string
  priority: boolean
}

export interface StageMediaProps {
  settings: {
    desktop: {
      media?: keyof TailwindBreakpoints
      video?: Asset | null
      image?: StageImageSettings
    }
    mobile: {
      media?: keyof TailwindBreakpoints
      video?: Asset | null
      image?: StageImageSettings
    }
  }
}

const renderVideo = (videoData: Asset | null) => (
  <VideoPlayer
    src={videoData?.url}
    autoPlay
    fullHeight
    fullWidth
    placeholderUrl={videoData?.url}
    placeholderAlt={videoData?.description}
    hideControls
  />
)

const renderImage = (image: StageImageSettings) => (
  <Image
    src={image?.data?.url!}
    alt={image?.data?.description ?? ''}
    fill={image?.fill}
    width={image?.data?.width as number}
    height={image?.data?.height as number}
    priority={image?.priority}
    sizes={image?.sizes}
    className={image?.className}
  />
)

export const StageMedia = ({ settings }: StageMediaProps) => {
  const { desktop, mobile } = settings
  const defaultBreakpoint = 'lgx'
  return (
    <>
      <Media className="h-full" lessThan={mobile.media ?? defaultBreakpoint}>
        <ClientOnly>{mobile.video && renderVideo(mobile.video)}</ClientOnly>
        {!mobile.video && mobile?.image && renderImage(mobile?.image)}
      </Media>
      <Media className="h-full" greaterThanOrEqual={desktop.media ?? defaultBreakpoint}>
        <ClientOnly>{desktop.video && renderVideo(desktop.video)}</ClientOnly>
        {!desktop?.video && desktop?.image && renderImage(desktop?.image)}
      </Media>
    </>
  )
}
