import { CategorySliderModuleItem, useCategorySliderModuleQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useGetSlidesSetup } from '../Carousel/hooks/useGetSlidesSetup'
import { CategorySliderItem } from './components/CategorySliderItem'
import { CategorySliderModulePlaceholder } from './components/CategorySliderModulePlaceholder'
import { useInView } from 'react-intersection-observer'
import { ProductsSlider } from '../ProductsSlider/ProductsSlider'
import { useCarouselGap } from '~/modules/Carousel/hooks/useCarouselGap'
import { getSpacingClasses } from '~/modules/helpers/getSpacingClasses'

interface CategorySliderModuleProps {
  id: string
}

export const CategorySliderModule = ({ id }: CategorySliderModuleProps) => {
  const gridConfig = {
    base: 1.5,
    xs: 1.5,
    sm: 3,
    md: 4,
    lg: 4,
    lgx: 4,
    xl: 4,
    '2xl': 4,
    '2k': 4,
    '4k': 4,
  }
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { slidesPerView } = useGetSlidesSetup(gridConfig)
  const [ref, inView] = useInView({ triggerOnce: true })

  const { data, isLoading } = useCategorySliderModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const carouselGap = useCarouselGap(10, 20)

  const teaserData = data?.categorySliderModule
  const items = (teaserData?.itemsCollection?.items as CategorySliderModuleItem[]) ?? []

  const topSpacingClass = getSpacingClasses(teaserData?.spacingTop, 'top')
  const bottomSpacingClass = getSpacingClasses(teaserData?.spacingBottom, 'bottom')

  if (isLoading && !inView) {
    return <CategorySliderModulePlaceholder />
  }

  return (
    <>
      <section
        ref={ref}
        className={`content-width relative box-border overflow-hidden pl-5 lg:px-[50px] ${topSpacingClass} ${bottomSpacingClass}`}
      >
        <div className="max-w-[1354px]">
          <ProductsSlider
            headlineClasses="lg:px-0"
            gridConfig={gridConfig}
            headlineText={teaserData?.title as string}
            products={items}
            gap={carouselGap}
            renderSlide={(product, index, isSlideVisible) => {
              return (
                <CategorySliderItem
                  data={product}
                  priority={index < slidesPerView}
                  isVisible={isSlideVisible}
                />
              )
            }}
          />
        </div>
      </section>
    </>
  )
}
