import { useEffect, useState } from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import { Maybe, Scalars, SeoLinkingCollections } from '@contentfulTypes'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import FacebookFallback from './assets/facebook_fallback.png'

type Props = {
  titleFallback: string
  textFallback: string
  __typename?: 'Seo'
  eMailSubject?: Maybe<Scalars['String']>
  eMailText?: Maybe<Scalars['String']>
  internTitle?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SeoLinkingCollections>
  ogImage?:
    | {
        __typename?: 'Asset' | undefined
        url?: string | null | undefined
        description?: string | null | undefined
        title?: string | null | undefined
        contentType?: string | null | undefined
      }
    | null
    | undefined
  ogLocale?: Maybe<Scalars['String']>
  ogSitename?: Maybe<Scalars['String']>
  ogTwitterDescription?: Maybe<Scalars['String']>
  ogTwitterTitle?: Maybe<Scalars['String']>
  ogUrl?: Maybe<Scalars['String']>
  seoCanonicalUrl?: Maybe<Scalars['String']>
  seoDescription?: Maybe<Scalars['String']>
  seoKeywords?: Maybe<Array<Maybe<Scalars['String']>>>
  seoNoFollow?: Maybe<Scalars['Boolean']>
  seoNoIndex?: Maybe<Scalars['Boolean']>
  seoTitle?: Maybe<Scalars['String']>
  twitterCreatorId?: Maybe<Scalars['String']>
  twitterImage?:
    | {
        __typename?: 'Asset' | undefined
        url?: string | null | undefined
        description?: string | null | undefined
        title?: string | null | undefined
        contentType?: string | null | undefined
      }
    | null
    | undefined
  twitterTweetText?: Maybe<Scalars['String']>
  whatsappImage?:
    | {
        __typename?: 'Asset' | undefined
        url?: string | null | undefined
        description?: string | null | undefined
        title?: string | null | undefined
        contentType?: string | null | undefined
      }
    | null
    | undefined
  whatsappText?: Maybe<Scalars['String']>
  withoutOgImage?: boolean
  languageAlternates?: NextSeoProps['languageAlternates']
}

export const Meta = ({
  titleFallback,
  textFallback,
  seoTitle,
  seoDescription,
  seoCanonicalUrl,
  seoNoIndex,
  seoNoFollow,
  languageAlternates,
  ogLocale,
  ogUrl,
  ogSitename,
  ogTwitterTitle,
  ogTwitterDescription,
  ogImage,
  twitterImage,
  whatsappImage,
  withoutOgImage = false,
}: Props) => {
  const [url, setUrl] = useState<string>('')
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE

  const baseUrl = `https://${
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? process.env.NEXT_PUBLIC_DOMAIN_NAME
      : process.env.NEXT_PUBLIC_VERCEL_URL
  }`

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const openGraph = withoutOgImage
    ? undefined
    : {
        type: 'Website',
        locale: ogLocale || finalLocale,
        url: ogUrl || baseUrl,
        site_name: ogSitename || titleFallback,
        title: ogTwitterTitle || titleFallback,
        description: ogTwitterDescription || textFallback,
        images: [
          {
            url: ogImage?.url || baseUrl + FacebookFallback.src,
            alt: ogImage?.description || titleFallback,
            type: ogImage?.contentType || 'image/jpeg',
            width: ogImage?.url ? 1200 : FacebookFallback.width,
            height: ogImage?.url ? 628 : FacebookFallback.height,
          },
          // {
          //   url: twitterImage?.url || baseUrl + TwitterFallback.src,
          //   alt: twitterImage?.description || titleFallback,
          //   type: twitterImage?.contentType || 'image/jpeg',
          //   width: twitterImage?.url ? 1200 : TwitterFallback.width,
          //   height: twitterImage?.url ? 675 : TwitterFallback.height,
          // },
          // {
          //   url: whatsappImage?.url || baseUrl + WhatsappFallback.src,
          //   alt: whatsappImage?.description || titleFallback,
          //   type: whatsappImage?.contentType || 'image/jpeg',
          //   width: whatsappImage?.url ? 300 : WhatsappFallback.width,
          //   height: whatsappImage?.url ? 300 : WhatsappFallback.height,
          // },
        ],
      }

  return (
    <NextSeo
      title={seoTitle || titleFallback}
      description={seoDescription || textFallback}
      canonical={seoCanonicalUrl || url}
      noindex={seoNoIndex ?? false}
      nofollow={seoNoFollow ?? false}
      openGraph={openGraph}
      twitter={{
        cardType: 'summary_large_image',
        site: '@benuta',
        handle: '@benuta',
      }}
      languageAlternates={languageAlternates}
    />
  )
}
