export const SeoHeadingModuleLoader = () => {
  return (
    <section className="content-width animate-pulse overflow-hidden px-5 lgx:px-[50px]">
      <div className="max-w-[1354px]">
        <div className="mb-5 h-4 w-3/4 rounded bg-sg-light-grey"></div>
        <div className="ml-5 max-w-[1080px] lg:ml-[50px]">
          <div className="h-[100px] w-full rounded bg-sg-light-grey"></div>
          <div className="mt-3 h-4 w-3/4 rounded bg-sg-light-grey lg:mt-5"></div>
        </div>
      </div>
    </section>
  )
}
