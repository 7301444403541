import { PropsWithChildren, ReactElement } from 'react'
import { AnimatedText } from './AnimatedText'

export const MultilineAnimatedText = ({
  text,
  showContent,
  renderText,
}: PropsWithChildren<{
  text: string
  showContent: boolean
  renderText: (text: string) => ReactElement
}>) => {
  // splitting the text by ' ' and '-' signs so every word is animated creating a feel of "animation per row"
  const textSplitByWord = text?.split(/(?=-)|(?<=-)|\s+/)

  return (
    <>
      {textSplitByWord?.map((word, index) => {
        let finalWord = ``
        // Omit '-' character because we are handling it below
        if (word === '-') {
          return
        }
        // Check if next element in the list is '-' we need to glue it with each other for proper wrapping
        if (textSplitByWord[index + 1] === '-') {
          finalWord = `${word.trim()}-&#8239`
        } else {
          finalWord = `${word.trim()}&#8239`
        }
        return (
          <AnimatedText
            key={word + index}
            className="inline-block"
            isVisible={showContent}
            delay={0}
            duration={300}
          >
            {renderText(finalWord)}
          </AnimatedText>
        )
      })}
    </>
  )
}
