import * as React from 'react'
import ReactPlayer from 'react-player/lazy'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { Asset } from '@contentfulTypes'
import styles from './VideoPlayer.module.css'
import { Image } from '~/elements/Image/Image'
import { useInView } from 'react-intersection-observer'
type TProps = {
  placeholderUrl?: string | Asset['url']
  placeholderAlt?: string | Asset['description']
  autoPlay?: boolean
  unmuted?: boolean
  src?: string | Asset['url']
  className?: string
  placeholderIsPoster?: boolean
  hideControls?: boolean
  pause?: boolean
  hidePlayButton?: boolean
  resetVideo?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  playButtonClass?: string
  placeholderSizes?: string
}

export const VideoPlayer = ({
  src,
  autoPlay,
  unmuted = false,
  placeholderUrl,
  placeholderAlt,
  className,
  hideControls,
  hidePlayButton,
  resetVideo,
  fullHeight,
  fullWidth,
  playButtonClass,
  placeholderSizes,
}: TProps) => {
  const playerRef = React.useRef<ReactPlayer>()
  const [error, setError] = React.useState(false)
  const [playing, setPlaying] = React.useState(false)
  const [ready, setReady] = React.useState(false)
  const { asPath } = useRouter()
  const [ref, inView] = useInView({ triggerOnce: true })

  const setPlayerRef = (player: ReactPlayer) => {
    playerRef.current = player
  }

  const onError = React.useCallback(() => {
    setError(true)
  }, [])

  const onReady = React.useCallback(() => {
    setReady(true)
  }, [])

  const onPlay = React.useCallback(() => {
    setPlaying(true)
  }, [])

  React.useEffect(() => {
    if (resetVideo && ready) {
      playerRef.current?.seekTo(0)
    }
  }, [resetVideo, ready])

  const showPlaceholderImage = placeholderUrl && !autoPlay && (error || !playing || !ready)
  const showPlay = !autoPlay && !error && ready && !playing && !hidePlayButton

  return (
    <div className={clsx('video-wrapper absolute inset-0', className)} ref={ref}>
      {showPlay && (
        <button
          className={clsx(
            'absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform',
            playButtonClass,
          )}
          onClick={() => setPlaying(true)}
        >
          <Image
            src={'/images/Icons/video-play.svg'}
            placeholder="empty"
            width={64}
            height={64}
            alt="usp-icon"
          />
        </button>
      )}
      {!error && (
        <div
          className={clsx(
            'video-player h-full w-full',
            fullHeight && styles.fullheight,
            fullWidth && styles.fullwidth,
          )}
        >
          {inView ? (
            <ReactPlayer
              ref={setPlayerRef}
              url={src as string}
              playing={autoPlay || playing}
              volume={0.5}
              // autoplaying videos are always muted for the users sake
              muted={autoPlay === true ? true : !unmuted}
              width="100%"
              height="100%"
              controls={autoPlay === true ? false : !hideControls}
              loop={autoPlay}
              onError={onError}
              onReady={onReady}
              onEnded={() => setPlaying(false)}
              playsInline
              config={{
                youtube: {
                  playerVars: {
                    modestBranding: 1,
                    rel: 0,
                    origin: process.env.NEXT_PUBLIC_SITE_URL + asPath,
                    playsInline: 1,
                  },
                },
                file: {
                  attributes: {
                    width: '100%',
                    height: '100%',
                    playsInline: true,
                  },
                },
                vimeo: {
                  playerOptions: {
                    portrait: false,
                    byline: false,
                    playsInline: true,
                    responsive: true,
                    title: false,
                    muted: autoPlay,
                  },
                },
              }}
            />
          ) : null}
        </div>
      )}

      {showPlaceholderImage && (
        <div className="absolute top-0 left-0 h-full w-full">
          <div className="relative">
            <Image
              src={placeholderUrl}
              alt={placeholderAlt as string}
              fill
              priority
              style={{ objectFit: 'cover' }}
              sizes={placeholderSizes ?? '100vw'}
            />
          </div>
        </div>
      )}
    </div>
  )
}
