import { NewsletterDisclaimer, useNewsletterQuery } from '@contentfulTypes'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { NewsletterContent } from './components/NewsletterContent'
import { getSpacingClasses } from '~/modules/helpers/getSpacingClasses'

type NewsletterProps = {
  id: string
}

export const Newsletter = ({ id }: NewsletterProps) => {
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useNewsletterQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  if (!data?.newsletter) return null

  const {
    headline,
    imageDesktop,
    imageMobile,
    disclaimer,
    subscriptionBenefits,
    spacingTop,
    spacingBottom,
  } = data?.newsletter || {}

  const benefits = (subscriptionBenefits || []).filter((benefit) => benefit !== null)

  const topSpacingClass = getSpacingClasses(spacingTop, 'top')
  const bottomSpacingClass = getSpacingClasses(spacingBottom, 'bottom')

  return (
    <div
      className={`mx-auto flex max-w-[1414px] flex-col px-5 lg:flex-row lg:px-0 ${topSpacingClass} ${bottomSpacingClass}`}
    >
      <NewsletterContent
        headline={headline}
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        disclaimer={disclaimer as NewsletterDisclaimer}
        subscriptionBenefits={benefits as string[]}
      />
    </div>
  )
}
