import { useLogoGridQuery } from '@contentfulTypes'
import { Image } from '@next/image'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const LogoGrid = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useLogoGridQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.logoGrid?.marginBottom as string,
    data?.logoGrid?.marginBottomMobile as string,
  )

  if (!data?.logoGrid) return null
  const { imagesCollection } = data.logoGrid

  return (
    <section
      style={margin}
      className={'relative mx-auto flex h-full w-full max-w-[1354px] justify-center'}
    >
      <ul className="grid h-full w-full grid-cols-2 bg-primary-white sm:grid-cols-3 md:grid-cols-4">
        {imagesCollection?.items.map((item) => (
          <li
            key={item?.sys.id}
            className="relative aspect-square border border-grey-medium-2 bg-primary-white"
          >
            <Image
              src={item?.url as string}
              alt={item?.description as string}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(max-width: 600px) 50vw, (max-width: 768px) 33vw, 25vw"
            />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default LogoGrid
