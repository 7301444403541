import {
  HeroStageModule,
  PageContentModulesCollection,
  Stage,
  usePreContentQuery,
} from '@contentfulTypes'
import { useRouteQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createGraphQLClient } from 'graphql/contentfulClient'
import selectModule from 'lib/selectModule'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { createElement, useEffect, useState } from 'react'
import { DEFAULT_LOCALE } from '~/config/constants'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { createMagentoClient } from '~/graphql/magentoClient'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { PageType, useSEO } from '~/hooks/useSEO'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { Breadcrumb } from '~/modules/Breadcrumb/Breadcrumb'
import { Meta } from '~/modules/Meta/Meta'
import { NewsletterPopup } from '~/modules/NewsletterPopup/NewsletterPopup'
import StageComp from '~/modules/Stage/Stage'
import { CorportationJsonLd } from '../StructuredData/CorporationJsonLd'
import { WebsisteJsonLd } from '../StructuredData/WebsiteJsonLd'
import { HeroStageModule as HeroStage } from '../HeroStageModule/HeroStageModule'
import { useNavigation } from '~/lib/Context/NavigationProvider'
import { ColorTheme } from '~/lib/constants/themeConstants'
type Props = {
  slug: string
  disableBreadcrumb?: boolean
  disableLanguageAlternates?: boolean
  paddingTop?: boolean
}

export const CmsPage = ({
  slug,
  disableBreadcrumb,
  disableLanguageAlternates,
  paddingTop = true,
}: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { dataLayer } = useGTM()
  const [hasNewsLetterPopup, setHasNewsletterPopup] = useState(false)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const stringSlug = Array.isArray(slug) ? slug.join('/') : slug
  const { data: contentData } = usePreContentQuery(contentfulClient, {
    slug,
    preview,
    locale: finalLocale,
  })

  const { data } = useRouteQuery(
    magentoClient,
    {
      slug: stringSlug?.replace(/^-/, ''),
    },
    { enabled: !!stringSlug && !disableLanguageAlternates },
  )
  const { canonical, languageAlternates } = useSEO({
    identifier: slug,
    type:
      !disableLanguageAlternates && (data?.route == null || data?.route?.type == 'CMS_PAGE')
        ? PageType.CTF_CMS_PAGE
        : PageType.DISABLED_PAGE,
  })

  const isPLP = data?.route?.type === 'CATEGORY'
  const isCartPage = slug === 'cart'

  const pageContent = contentData?.pageCollection?.items?.[0]
  const content = pageContent?.contentModulesCollection as PageContentModulesCollection
  const heroStageModule = pageContent?.stageModule as HeroStageModule
  const stage = pageContent?.stage as Stage
  const metaData = pageContent?.meta
  const { setNavigationTheme } = useNavigation()

  useEffect(() => {
    if (!disableBreadcrumb || heroStageModule) {
      dataLayer(
        DataLayerEnum.PAGE,
        data?.route?.type === 'CATEGORY'
          ? 'category'
          : slug == 'cart'
          ? 'cart'
          : slug == 'home'
          ? 'home'
          : 'other',
      )
    }
  }, [dataLayer, disableBreadcrumb, data?.route?.type, slug, heroStageModule])

  useEffect(() => {
    if (router?.query?.newsletterConfirmation) {
      setHasNewsletterPopup(true)
    } else {
      setHasNewsletterPopup(false)
    }
  }, [router?.query?.newsletterConfirmation])

  useEffect(() => {
    const navigationTheme = contentData?.pageCollection?.items?.[0]?.navigationTheme as ColorTheme

    if (navigationTheme) {
      setNavigationTheme(navigationTheme)
    }

    return () => {
      setNavigationTheme('dark')
    }
  }, [setNavigationTheme, contentData])

  const closePopup = async () => {
    setHasNewsletterPopup(false)
    await router.replace('/', undefined, { shallow: true })
  }

  const isBlogWithoutOgImage = slug.toLocaleLowerCase().includes('blog/') && !metaData?.ogImage

  type StageComponentProps = {
    heroStageModule?: HeroStageModule | null
    stage?: Stage | null
    isBlogWithoutOgImage?: boolean
  }

  const selectStageComponent = ({
    heroStageModule,
    stage,
    isBlogWithoutOgImage,
  }: StageComponentProps) => {
    if (heroStageModule) {
      return <HeroStage id={heroStageModule.sys?.id} />
    }

    if (stage) {
      return (
        <StageComp
          id={stage.sys?.id}
          imageLoadStrategy="eager"
          isBlogWithoutOgImage={isBlogWithoutOgImage}
          mainStage
          {...stage}
        />
      )
    }

    return null
  }

  return (
    <>
      <Meta
        {...metaData}
        withoutOgImage={isBlogWithoutOgImage}
        seoNoIndex={isPLP ? false : pageContent?.preventIndexing || metaData?.seoNoIndex}
        seoNoFollow={isPLP ? false : pageContent?.preventIndexing || metaData?.seoNoFollow}
        titleFallback={t('meta.titleFallback')}
        textFallback={t('meta.textFallback')}
        seoCanonicalUrl={canonical && languageAlternates ? canonical : undefined}
        languageAlternates={canonical && languageAlternates ? languageAlternates : undefined}
      />
      {hasNewsLetterPopup ? (
        <NewsletterPopup isActive={hasNewsLetterPopup} onClick={closePopup} />
      ) : null}
      <div className={heroStageModule || !paddingTop ? '' : 'pt-[var(--header-padding)]'}>
        {!disableBreadcrumb && !heroStageModule && !isPLP && !isCartPage ? (
          <ClientOnly>
            <Breadcrumb tags={pageContent?.contentfulMetadata?.tags} />
          </ClientOnly>
        ) : null}

        {/* STAGE COMPONENT */}
        {selectStageComponent({ heroStageModule, stage, isBlogWithoutOgImage })}

        {content?.items.map((module, i) => {
          const moduleName = module?.__typename?.toLowerCase() as string
          const id = module?.sys?.id as string
          const component = selectModule(moduleName)

          if (!moduleName || !component) {
            return null
          }

          return createElement(component, {
            key: `${moduleName}_${id}_${i}`,
            id,
            ...module,
          })
        })}
      </div>
      {'home' === slug && (
        <>
          <CorportationJsonLd />
          <WebsisteJsonLd />
        </>
      )}
    </>
  )
}
export default CmsPage
