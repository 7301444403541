const spacingValues = {
  none: null,
  small: {
    top: 'pt-[50px]',
    bottom: 'pb-[50px]',
  },
  medium: {
    top: 'pt-[50px] lgx:pt-[100px]',
    bottom: 'pb-[50px] lgx:pb-[100px]',
  },
  big: {
    top: 'pt-[100px] lgx:pt-[200px]',
    bottom: 'pb-[100px] lgx:pb-[200px]',
  },
}

type SpacingValue = keyof typeof spacingValues

export const getSpacingClasses = (
  spacing: string | null | undefined,
  position: 'top' | 'bottom',
) => {
  if (!spacing) return

  const value = spacing || 'medium'

  return spacingValues[value as SpacingValue]?.[position]
}
