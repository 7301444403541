import { TextPicTeaserItem, useTextPicTeaserModuleQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { StageSliderCarouselModule } from '../StageSliderCarouselModule/StageSliderCarouselModule'
import { TextPicItem } from './components/TextPicItem'
import { TextPicModulePlaceholder } from './components/TextPicModulePlaceholder'
import { StageSliderCarouselProgressBar } from '../StageSliderCarouselModule/components/StageSliderCarouselProgressBar'
import { ColorTheme } from '~/lib/constants/themeConstants'

interface TextPicTeaserModuleProps {
  id: string
}

export const TextPicTeaserModule = ({ id }: TextPicTeaserModuleProps) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, isLoading } = useTextPicTeaserModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const teaserData = data?.textPicModule
  const items =
    (teaserData?.itemsCollection?.items as Array<TextPicTeaserItem & { theme: ColorTheme }>) ?? []

  if (isLoading) {
    return <TextPicModulePlaceholder />
  }

  return (
    <div className="content-width">
      <StageSliderCarouselModule displayNavigation={items.length > 1} items={items}>
        {{
          slideComponent: (
            slideData: TextPicTeaserItem & { theme: string },
            index: number,
            isActive,
          ) => {
            return <TextPicItem data={slideData} isFirstItem={index === 0} isActive={isActive} />
          },
          customProgressBar: (progressPercentage, handleClick, theme) => {
            return (
              <div className="z-20 lg:hidden">
                <StageSliderCarouselProgressBar
                  theme={theme?.toLowerCase() as ColorTheme}
                  progressPercentage={progressPercentage}
                  handleClick={handleClick}
                />
              </div>
            )
          },
        }}
      </StageSliderCarouselModule>
    </div>
  )
}
