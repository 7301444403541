import clsx from 'clsx'
import { ColorTheme } from '~/lib/constants/themeConstants'

export interface CustomProgressBarProps {
  currentSlide?: number
  progressPercentage: number
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void
  theme?: ColorTheme
}

export const StageSliderCarouselProgressBar = ({
  progressPercentage,
  handleClick,
  theme,
}: CustomProgressBarProps) => (
  <div className="absolute left-5 right-5 bottom-10 z-10 flex lgx:left-1/2 lgx:bottom-20 lgx:w-full lgx:max-w-[335px] lgx:-translate-x-1/2">
    <div
      className={clsx('h-0.5 w-full rounded-full bg-sg-grey')}
      role="progressbar"
      aria-valuenow={progressPercentage}
      aria-valuemin={0}
      aria-valuemax={100}
      onClick={handleClick}
    >
      <div
        className={clsx(
          'h-full rounded-full transition-all duration-300 ease-in-out',
          theme === 'light' ? 'bg-sg-white' : 'bg-sg-black',
        )}
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  </div>
)
