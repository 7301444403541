import { useSeoHeadingModuleQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { SeoHeadingModuleLoader } from './SeoHeadingModuleLoader'
import { getSpacingClasses } from '~/modules/helpers/getSpacingClasses'
import clsx from 'clsx'

interface SeoHeadingModuleProps {
  id: string
}

export const SeoHeadingModule = ({ id }: SeoHeadingModuleProps) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, isLoading } = useSeoHeadingModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const teaserData = data?.seoHeadingModule
  const topSpacingClass = getSpacingClasses(teaserData?.spacingTop, 'top')
  const bottomSpacingClass = getSpacingClasses(teaserData?.spacingBottom, 'bottom')

  if (isLoading) {
    return <SeoHeadingModuleLoader />
  }

  return (
    <section
      className={clsx(
        'content-width overflow-hidden px-5 lgx:px-[50px]',
        topSpacingClass,
        bottomSpacingClass,
      )}
    >
      <div className="max-w-[1354px] text-center">
        <p className="mb-5 text-15-22-sg font-extralight lg:text-20-25-sg">{teaserData?.subline}</p>
        <h1 className="text-20-25-sg font-extralight lg:text-50-60-sg">{teaserData?.headline}</h1>
        <p className="mt-3 text-15-22-sg font-extralight text-sg-dark-grey lg:mt-5 lg:text-20-25-sg">
          {teaserData?.description}
        </p>
      </div>
    </section>
  )
}
