import clsx from 'clsx'
import { Icon } from '~/elements/Icons/Icon'
import { ColorTheme } from '~/lib/constants/themeConstants'

export type SlideNavigationProps = {
  clickPrev: () => void
  clickNext: () => void
  disablePrev?: boolean
  disableNext?: boolean
  className?: string
  theme?: ColorTheme
}

export const SlideNavigation = ({
  clickPrev,
  clickNext,
  disablePrev,
  disableNext,
  className = '',
  theme = 'dark',
}: SlideNavigationProps) => {
  return (
    <div
      className={clsx(
        'pointer-events-auto w-full max-w-[128px] items-center justify-between',
        className,
      )}
    >
      <button
        className={clsx({
          'cursor-default opacity-40': disablePrev,
          'cursor-pointer': !disablePrev,
        })}
        disabled={disablePrev}
        aria-label="Previous slide"
        onClick={clickPrev}
      >
        <Icon name={`content-arrow-left-${theme}`} />
      </button>
      <button
        className={clsx({
          'cursor-pointer': !disableNext,
          'cursor-default opacity-40': disableNext,
        })}
        disabled={disableNext}
        aria-label="Next slide"
        onClick={clickNext}
      >
        <Icon name={`content-arrow-right-${theme}`} />
      </button>
    </div>
  )
}
