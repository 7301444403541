import { CategorySliderModuleItem } from '@contentfulTypes'
import { Media } from '~/lib/Media'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'
import { AnimatedText } from '~/modules/AnimatedText/AnimatedText'
import clsx from 'clsx'

type CategorySliderItemProps = {
  data: CategorySliderModuleItem
  priority: boolean
  isVisible: boolean
}
export const CategorySliderItem = ({ data, priority, isVisible }: CategorySliderItemProps) => {
  const { image, mobileImage, link, linkExistingPage, title } = data

  const linkTo = linkExistingPage?.slug ?? link
  const linkText = linkExistingPage?.title ?? data.title

  return (
    <Link
      href={linkTo ?? ''}
      className={clsx(
        'flex h-full w-full flex-col transition-opacity duration-500',
        isVisible ? 'opacity-100' : 'opacity-0',
      )}
      title={linkText ?? ''}
    >
      <div className="relative h-full w-full overflow-hidden">
        <Media className="h-full w-full" greaterThanOrEqual="lgx">
          <Image
            src={image?.url!}
            alt={image?.description ?? ''}
            width={image?.width as number}
            height={image?.height as number}
            priority={priority}
            className="aspect-[1/1.4] h-full w-full transform object-cover transition-transform duration-500 hover:scale-110"
          />
        </Media>
        <Media className="h-full" lessThan="lgx">
          <Image
            src={mobileImage?.url! ?? image?.url!}
            alt={mobileImage?.description ?? image?.description ?? ''}
            width={(mobileImage?.width as number) ?? (image?.width as number)}
            height={(mobileImage?.height as number) ?? (image?.width as number)}
            priority={priority}
            className="aspect-[1/1.5] h-full w-full object-cover"
          />
        </Media>
      </div>
      <AnimatedText className="leading-[22px]" isVisible={isVisible}>
        <div className="mt-5 flex h-full items-start pb-1 text-15-22-sg font-extralight transition-opacity duration-300 hover:opacity-50 lg:mt-7.5">
          {title}
        </div>
      </AnimatedText>
    </Link>
  )
}
