import { useRef, useCallback, useState, ReactElement } from 'react'
import { Carousel, CarouselControls } from '~/modules/Carousel/components/Carousel'
import { SlideNavigation } from './components/StageSliderCarouselNavigation'
import tailwindConfig from 'theme'
import { CarouselColumnsConfig } from '../Carousel/hooks/useGetSlidesSetup'
import { ColorTheme } from '~/lib/constants/themeConstants'
import clsx from 'clsx'

export type CarouselBreakpoints = keyof typeof tailwindConfig.theme.carousel

export type StageSliderCarouselControls = {
  handleNextClick: () => void
  handlePrevClick: () => void
  isAtStart: boolean
  isAtEnd: boolean
}

// TODO: fix items type
interface StageSliderCarouselModuleProps<T> {
  className?: string
  gridConfig?: CarouselColumnsConfig
  items: any[]
  displayNavigation?: boolean
  displayProgressBar?: boolean
  scrollBy?: number
  children: {
    slideComponent: (
      data: T,
      index: number,
      isActive: boolean,
      { handleNextClick, handlePrevClick, isAtStart, isAtEnd }: StageSliderCarouselControls,
    ) => JSX.Element
    customProgressBar?: (
      progressPercentage: number,
      handleClick: (event: React.MouseEvent<HTMLDivElement>) => void,
      theme: ColorTheme,
    ) => ReactElement
  }
}

export const StageSliderCarouselModule = <T extends unknown>({
  className,
  items,
  scrollBy = 1,
  displayNavigation,
  displayProgressBar,
  gridConfig = {
    base: 1,
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    lgx: 1,
    xl: 1,
    '2xl': 1,
    '2k': 1,
    '4k': 1,
  },
  children,
}: StageSliderCarouselModuleProps<T>) => {
  const carouselControlsRef = useRef<CarouselControls | null>(null)
  const [theme, setTheme] = useState<ColorTheme>()
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(1)
  const ANIMATION_DURATION = 300

  const handlePrevClick = useCallback(() => {
    carouselControlsRef.current?.slideToPrev()
  }, [])

  const handleNextClick = useCallback(() => {
    carouselControlsRef.current?.slideToNext()
  }, [])

  const handleNavigationStateChange = useCallback((atStart: boolean, atEnd: boolean) => {
    setTimeout(() => {
      setIsAtStart(atStart)
      setIsAtEnd(atEnd)
    }, ANIMATION_DURATION)
  }, [])

  const handleNavigationStateStart = useCallback(
    (index: number) => {
      const slide = items.find((_, i) => i === index - 1)
      setTheme(slide?.theme)
    },
    [items],
  )

  const handleSlideChangeEnd = useCallback(
    (index: number) => {
      setTimeout(() => {
        setCurrentSlide(index)
        const atStart = index === 1
        const atEnd = index === items.length
        setIsAtStart(atStart)
        setIsAtEnd(atEnd)
      }, ANIMATION_DURATION / 2)
    },
    [items.length],
  )

  return (
    <>
      <div className={clsx('relative', className)}>
        <Carousel
          items={items}
          gridConfig={gridConfig}
          progressbar={displayProgressBar}
          renderSlide={(item, index, controls) => {
            if (index === 0) {
              carouselControlsRef.current = controls
            }
            return children.slideComponent(item, index, currentSlide === index + 1, {
              isAtStart,
              isAtEnd,
              handleNextClick,
              handlePrevClick,
            })
          }}
          renderCustomProgressBar={({ progressPercentage, handleClick }) =>
            children?.customProgressBar?.(progressPercentage, handleClick, theme as ColorTheme)
          }
          scrollBy={scrollBy}
          onNavigationStateChange={handleNavigationStateChange}
          onSlideChangeEnd={handleSlideChangeEnd}
          onNavigationStateStart={handleNavigationStateStart}
        />
        {displayNavigation && (
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center lgx:px-5">
            <div className="z-20 flex w-full max-w-[1414px] justify-end px-[30px]">
              <SlideNavigation
                className="hidden gap-5 lg:flex"
                theme={(theme?.toLowerCase() as ColorTheme) ?? ('dark' as ColorTheme)}
                disableNext={isAtEnd}
                disablePrev={isAtStart}
                clickNext={handleNextClick}
                clickPrev={handlePrevClick}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
