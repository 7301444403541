import { RteRteLinks, useBlackfridayNewsletterModuleQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { Headline } from '~/elements/Headline/Headline'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { createMagentoClient } from '~/graphql/magentoClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { Input } from '~/elements/Input/Input'
import { useSubscribeEmailToNewsletterMutation } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { Image } from '@next/image'
import { useMedia } from '~/hooks/useMedia'
import clsx from 'clsx'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { ButtonMain } from '~/elements/Button/ButtonMain'

type Props = {
  id: string
}

type FormData = {
  email: string
}

const BlackFridayNewsletterModule = ({ id }: Props) => {
  const [subscribeSuccessful, setSubscribeSuccessful] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const { t } = useTranslation()
  const preview = usePreviewMode()
  const router = useRouter()
  const { dataLayer } = useGTM()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useBlackfridayNewsletterModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const isDesktop = useMedia('lgx')
  const margin = useMarginMaker(
    data?.blackfridayNewsletterModule?.marginBottom as string,
    data?.blackfridayNewsletterModule?.marginBottomMobile as string,
  )

  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const queryClient = useQueryClient()
  const mutationClient = createMagentoClient({ queryClient, usePost: true, locale: finalLocale })
  const newsletterMutation = useSubscribeEmailToNewsletterMutation(mutationClient, {
    onSuccess: async (data) => {
      toast.success(t('Your first step in subscribing to our newsletter was successful!'))
      setSubscribeSuccessful(true)
    },
    onError: async (error) => {
      if (error.message.includes('already subscribed')) {
        toast.error(t('This email address is already subscribed.'))
      } else if (error.message.includes('Guests can not subscribe')) {
        toast.error(
          t('Guests can not subscribe to the newsletter. Please create an account to subscribe.'),
        )
      } else if (error.message.includes('account sign-in was incorrect')) {
        toast.error(
          t(
            'Your account is pending confirmation or disabled temporarily. Please try again later.',
          ),
        )
      } else {
        toast.error(t('Something went wrong. Please try again later.'))
      }
      setSubscribeSuccessful(false)
    },
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = handleSubmit(async ({ email }) => {
    newsletterMutation.mutate({ email })
    dataLayer(DataLayerEnum.EVENT, 'sign_up', {
      method: 'hp_box',
      user_data: {
        email_address: email,
      },
    })
    setCurrentEmail(email)
    reset()
  })

  if (!data?.blackfridayNewsletterModule) return null
  const {
    headline,
    text,
    subline,
    emailPlaceholder,
    ctaButton,
    disclaimerOne,
    disclaimerTwo,
    textDirection,
    imageHeadline,
    imageSubline,
    desktopBackground,
    mobileBackground,
    theme,
  } = data?.blackfridayNewsletterModule

  return (
    <section
      className="relative flex h-full w-full max-w-[100vw] flex-col items-center overflow-hidden p-4 pt-8 md:flex-row md:justify-center md:p-8 lg:px-20"
      style={margin}
    >
      <div className="relative w-full max-w-[1280px] bg-cover bg-left-bottom bg-no-repeat p-6 lgx:flex lgx:items-center lgx:p-0 xl:max-w-[1600px]">
        {isDesktop ? (
          <Image
            hasNoBlur
            src={desktopBackground?.url}
            alt=""
            fill
            sizes="80vw"
            className="absolute hidden h-full w-full object-cover object-center lg:block"
          />
        ) : (
          <Image
            hasNoBlur
            src={mobileBackground?.url}
            alt=""
            fill
            className="absolute -z-10 h-full w-full object-cover lg:hidden"
          />
        )}
        <div
          className={clsx(
            'z-10 flex w-full flex-col justify-center lgx:h-[367px] lgx:w-[50%] lgx:p-[40px]',
            {
              'mt-6 items-end xl:mt-14': !textDirection,
              'mt-4 items-center lg:items-end xl:mt-10 xl:-ml-2 2xl:ml-8': textDirection,
            },
          )}
        >
          <Headline
            className={clsx('!text-primary-white', {
              '!text-[35px] !tracking-[0.07em] lgx:!text-[50px] lgx:!tracking-[0.08em] xl:!text-[60px]':
                !textDirection,
              '!text-[60px] xl:!text-[120px] 2xl:!text-[130px]': textDirection,
            })}
            type="h2"
            styleAs="h1"
          >
            {imageHeadline}
          </Headline>
          <Headline
            className={clsx('font-light !text-primary-white', {
              '-mt-1 !text-[24px] !tracking-[0.1em] lg:mt-2 lgx:!text-[40px] lgx:!tracking-[0.08em]':
                !textDirection,
              'mt-4 !text-[40px] lgx:mt-6 lgx:!text-[55px]': textDirection,
            })}
            type="h3"
            styleAs="h1"
          >
            {imageSubline}
          </Headline>
        </div>

        <div className="z-10 h-auto w-full lgx:w-[50%] lgx:max-w-[1000px] lgx:p-[45px]">
          <div
            className={clsx('rounded-[15px] p-6 lgx:p-14', {
              'bg-[#252525]': theme,
              'bg-primary-white/70': !theme,
              'mt-4 mb-[134px] lgx:mt-0 lgx:mb-0': !textDirection,
              'mt-8 mb-[150px] lgx:mt-0 lgx:mb-0': textDirection,
            })}
          >
            <div className="lgx:hidden">
              <Headline
                className={clsx('!text-[45px]', {
                  '!text-primary-white': theme,
                  '!text-[#252525]': !theme,
                })}
                type="h2"
                styleAs="h1"
              >
                {headline}
              </Headline>
              <Headline
                className={clsx('my-3 !text-[25px] font-light', {
                  '!text-primary-white': theme,
                  '!text-primary-black': !theme,
                })}
                type="h3"
                styleAs="h2"
              >
                {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
              </Headline>
            </div>
            <div className="hidden lgx:block">
              <Headline
                className={clsx('!text-[60px]', {
                  '!text-primary-white': theme,
                  '!text-[#252525]': !theme,
                })}
                type="h2"
                styleAs="h1"
              >
                {headline}
              </Headline>
              {text && (
                <Headline
                  className={clsx(
                    'my-3 !text-[30px] !font-light !leading-[2rem] !tracking-[0.05em]',
                    {
                      '!text-primary-white': theme,
                      '!text-primary-black': !theme,
                    },
                  )}
                  type="h3"
                  styleAs="h2"
                >
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </Headline>
              )}
            </div>
            <div
              className={clsx('text-[14px] font-light lgx:text-[20px]', {
                '!text-primary-white': theme,
                '!text-primary-black': !theme,
              })}
            >
              {subline}
            </div>
            <form className="-mt-4 lgx:mt-4" onSubmit={onSubmit}>
              <div className="grid grid-cols-1 lgx:grid-cols-3">
                <div className="mr-0 mt-auto -mb-6 pt-8 lgx:col-span-2 lgx:mr-8 lgx:pt-0">
                  <Input<FormData>
                    classNameInputELement={clsx({
                      '!text-grey-light !placeholder-grey-light !caret-grey-light !border-grey-light':
                        theme,
                      '!text-primary-black !placeholder-primary-black !caret-primary-black !border-primary-black':
                        !theme,
                    })}
                    classNameInputPlaceholder={clsx({ '!text-primary-black': !theme })}
                    classNameInputContainer="!text-grey-red !placeholder-grey-dark !caret-grey-dark !border-grey-dark"
                    classNameInputError="relative lgx:absolute !w-full !lgx:py-4 !font-bold !text-p-small !text-[#cf2828]"
                    name="email"
                    type="text"
                    placeholder={emailPlaceholder as string}
                    autoComplete="email"
                    rules={{
                      required: {
                        value: true,
                        message: t('{{val}} is required', { val: t('E-Mail') }),
                      },
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: t('Please enter a valid email'),
                      },
                    }}
                    register={register}
                    errors={errors}
                    id="blackFridayNewsletter_email"
                  />
                </div>
                <ButtonMain
                  theme={theme ? 'light' : 'dark'}
                  className={clsx('mt-3 !px-0 lgx:mt-0')}
                  type="submit"
                >
                  {ctaButton}
                </ButtonMain>
              </div>
              {subscribeSuccessful ? (
                <p className="mt-4 w-full text-p-small font-bold text-[#2bb305]">
                  {`${t(
                    'You are almost there! Please confirm your subscription in the email we just sent to',
                  )} ${currentEmail}.`}
                </p>
              ) : null}
              {disclaimerOne && (
                <RTEParser
                  className={clsx(
                    'mt-2 text-[#ABABAB] lgx:mt-6 lgx:text-p-small [&>p]:text-[8px] [&>p]:leading-3 lgx:[&>p]:text-[12px] lgx:[&>p]:leading-5',
                    {
                      'text-[#ABABAB]': theme,
                      'text-[#6D6A6A]': !theme,
                    },
                  )}
                  rteJSONContent={disclaimerOne?.json}
                  links={disclaimerOne?.links as RteRteLinks}
                />
              )}
              {disclaimerTwo && (
                <RTEParser
                  className={clsx(
                    '-mt-2 -mb-2 lgx:mb-0 lgx:text-p-small [&>p]:text-[8px] [&>p]:leading-3 lgx:[&>p]:text-[12px] lgx:[&>p]:leading-5',
                    {
                      'text-primary-white': theme,
                      'text-[#343333]': !theme,
                    },
                  )}
                  rteJSONContent={disclaimerTwo?.json}
                  links={disclaimerTwo?.links as RteRteLinks}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlackFridayNewsletterModule
