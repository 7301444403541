import * as React from 'react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ErrorMessage } from '@hookform/error-message'
import styles from './Input.module.css'
import { RegisterOptions, UseFormRegister, Path, FieldErrors, FieldValues } from 'react-hook-form'
import { Icon } from '../Icons/Icon'

export type InputProps = {
  children?: React.ReactNode
  classNameInputELement?: string
  classNameInputContainer?: string
  classNameInputWrapper?: string
  classNameInputPlaceholder?: string
  classNameInputError?: string
  name: string
  type?: 'text' | 'email' | 'tel' | 'password' | 'number' | 'radio'
  maxLength?: number
  required?: boolean
  autoComplete?: string
  placeholder?: string
  disabled?: boolean
  defaultValue?: string | null
  hasInitialValue?: boolean
  id?: string
}

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  register: UseFormRegister<TFormValues>
  rules: RegisterOptions
  errors: FieldErrors<TFormValues>
} & Omit<InputProps, 'name'>

export const Input = <TFormValues extends FieldValues>({
  children,
  required,
  name,
  errors,
  maxLength,
  autoComplete,
  disabled,
  placeholder,
  classNameInputELement = '',
  classNameInputContainer = '',
  classNameInputWrapper = '',
  classNameInputPlaceholder = '',
  classNameInputError = '',
  register,
  rules,
  defaultValue = '',
  hasInitialValue,
  id,
  ...rest
}: FormInputProps<TFormValues>) => {
  const hasError = !!errors?.[name]
  const { t } = useTranslation()

  //To ensure autofilled value is detected, animation on autofill-pseudo element is set up
  const [value, setValue] = React.useState(defaultValue)

  return (
    <div className={clsx(classNameInputWrapper, 'relative')}>
      <div
        className={clsx(
          classNameInputContainer && classNameInputContainer,
          'input-wrapper relative mb-6',
        )}
      >
        <input
          disabled={disabled}
          maxLength={maxLength}
          required={required}
          autoComplete={autoComplete}
          value={value ?? undefined}
          className={clsx(
            styles.input,
            '!focus:outline-none peer z-10 h-10 w-full appearance-none rounded-[5px] border-sg-grey bg-transparent px-[19px] py-[12px] text-12-20-sg placeholder-sg-grey caret-grey-dark !outline-none outline-0 transition-all focus:border-sg-black focus:ring-0 lgx:h-[46px]',
            classNameInputELement && classNameInputELement,
            classNameInputPlaceholder && classNameInputPlaceholder,
            hasError && !disabled && 'text-sg-red',
            disabled && 'pointer-events-none',
            !hasError && !disabled && value && '!border-sg-black',
          )}
          onFocus={(e) => {
            setValue(e.target.value)
          }}
          {...rest}
          {...(register &&
            register(name, {
              onChange: (e) => {
                setValue(e.target.value)
              },

              ...rules,
            }))}
          id={id}
          placeholder={`${placeholder}${!rules.required ? ` (${t('Optional')})` : '*'}`}
        ></input>
        {children}
      </div>
      {hasError && !disabled && (
        <p className="absolute right-4 top-1/2 -translate-y-1/2">
          <Icon name="error" className="h-4.5 w-4.5" />
        </p>
      )}
      {!hasError && !disabled && value && !children && (
        <p className="absolute right-4 top-1/2 -translate-y-1/2">
          <Icon name="checkmark" className="h-4.5 w-4.5" />
        </p>
      )}
      {!disabled && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message }) => (
            <p
              className={clsx(
                'error-message absolute top-full ml-5 pt-0.5 text-p-small font-extralight text-primary-red',
                classNameInputError && classNameInputError,
              )}
            >
              {t(message)}
            </p>
          )}
        />
      )}
    </div>
  )
}
