import { useSeoTextModuleQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { SeoText } from '../SeoText/SeoText'
import { getSpacingClasses } from '~/modules/helpers/getSpacingClasses'
const SeoTextModule = ({ id }: { id: string }) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useSeoTextModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const { items } = data?.seoTextModule?.seoTextsCollection ?? { items: [] }

  const topSpacingClass = getSpacingClasses(data?.seoTextModule?.spacingTop, 'top')
  const bottomSpacingClass = getSpacingClasses(data?.seoTextModule?.spacingBottom, 'bottom')

  return (
    <div className={`content-width px-5 lg:px-[50px] ${topSpacingClass} ${bottomSpacingClass}`}>
      <section className="max-w-[1354px]">
        {items.map((item, index) => (
          <SeoText
            key={index}
            className={index === items.length - 1 ? '' : 'mb-[50px]'}
            headline={item?.headline ?? ''}
            seoText={item?.seoText?.json}
            links={item?.seoText?.links}
          />
        ))}
      </section>
    </div>
  )
}

export default SeoTextModule
